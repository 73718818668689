<template>
  <div class="blog knowledge-base-details relative landing-page-wrapper new-langing-ui">
    <landing-page-header
      class="customer-landing-page-header"
      :nav-class="'light'"
    />
    <content-with-sidebar class="blog-wrapper content-info no-white-space">
      <!-- blogs -->
      <b-row
        v-if="blog"
        class="blog-list-wrapper"
      >
        <b-col md="12">
          <b-card
            tag="article"
            class="articles-wrapper"
            no-body
          >
            <b-link>
              <b-img
                :src="$superApp.defaultImage"
                class="card-img-top img-details-blog"
                @error="$helpers.imageError"
              />
            </b-link>
            <b-card-body>
              <b-card-title class="d-flex align-items-center justify-content-between">
                <b-link class="text-body-heading">
                  {{ blog.question }}
                </b-link>
              </b-card-title>
              <div
                v-if="blog.tags"
                class="my-1 py-25"
              >
                <b-link
                  v-for="(tag, count) in blog.tags"
                  :key="count"
                >
                  <b-badge
                    pill
                    class="mr-75"
                    :variant="tagsColor(tag)"
                  >
                    {{ tag }}
                  </b-badge>
                </b-link>
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <b-card-text
                class="mb-2 mt-2"
                v-html="blog.answer"
              />
              <!--eslint-enable-->
              <div class="d-flex justify-content-between align-items-center">
                <b-link
                  v-if="blog.comment"
                  :to="{ path: `/pages/blog/${blog.id}#blogComment` }"
                >
                  <div class="d-flex align-items-center text-body">
                    <feather-icon
                      icon="MessageSquareIcon"
                      class="mr-50"
                    />
                    <span class="font-weight-bold">{{ kFormatter(blog.comment) }} {{ $t('Comments') }}</span>
                  </div>
                </b-link>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!--/ blogs -->

      <!-- sidebar -->
      <div
        slot="sidebar"
        class="blog-sidebar py-2 py-lg-0"
      >
        <!-- input search -->
        <b-form-group class="blog-search">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search-input"
              v-model="search_query"
              placeholder="Search here"
            />
            <b-input-group-append
              class="cursor-pointer"
              is-text
            >
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!--/ input search -->

        <!-- recent posts -->
        <div class="blog-recent-posts mt-1">
          <h6 class="section-label mb-75">
            {{ $t('Recent Posts') }}
          </h6>
          <b-media
            v-for="(recentpost, index) in blogRecentPost"
            :key="index"
            no-body
            :class="index ? 'mt-2' : ''"
          >
            <b-media-aside class="mr-2">
              <b-link :to="{ path: getViewUrl(recentpost) }">
                <b-img
                  :src="$helpers.imageHelper(recentpost.category.picture)"
                  width="100"
                  rounded
                  height="70"
                  class="fit-image-contains"
                  @error="$helpers.imageError"
                />
              </b-link>
            </b-media-aside>
            <b-media-body>
              <h6 class="blog-recent-post-title">
                <b-link
                  :to="{ path: getViewUrl(recentpost) }"
                  class="text-body-heading"
                >
                  {{ recentpost.question }}
                </b-link>
              </h6>
              <span class="text-muted mb-0">
                {{ formatDate(recentpost.created_at) }}
              </span>
            </b-media-body>
          </b-media>
        </div>
        <!--/ recent posts -->
      </div>
      <!--/ sidebar -->
    </content-with-sidebar>
    <landing-page-footer />
  </div>
</template>

<script>
import { BRow, BCol, BCard, BFormInput, BCardText, BCardTitle, BMedia, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import moment from 'moment'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { useUserUi } from './users/useUser'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    ContentWithSidebar,
    LandingPageHeader,
    LandingPageFooter,
  },
  data() {
    return {
      showPagination: false,
      search_query: '',
      blogList: [],
      blogRecentPost: [],
      blogSidebar: {},
      currentPage: 1,
      blog: {},
      perPage: 1,
      rows: 140,
    }
  },
  watch: {
    '$route.params.knowledgeBaseId': {
      handler() {
        this.knowledgeBaseQuestionById()
      },
    },
  },
  mounted() {
    this.knowledgeBaseQuestionById()
    this.knowledgeBaseLatest()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm A')
    },
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
    getViewUrl(blog) {
      return `/knowledge-base/details/${blog.question.trim().toLowerCase().replace(/ /g, '-').replace(/[^a-zA-Z- ]/g, '')}/${blog.category_id}/${blog.id}`
    },
    knowledgeBaseLatest() {
      const { knowledgeBaseLatest } = useUserUi()
      showLoader()
      knowledgeBaseLatest()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.blogRecentPost = data.responseData
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    knowledgeBaseQuestionById() {
      const { knowledgeBaseQuestionById } = useUserUi()
      showLoader()
      knowledgeBaseQuestionById(this.$route.params.knowledgeBaseId)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.blog = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
.blog.knowledge-base-details .cws-container .cws-sidebar {
  top: 6.5rem;
  right: 15px;
}
</style>

<style scoped>
.landing-page-wrapper .content-info {
  padding-top: 6.5rem;
  padding-left: 10px;
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing-page.scss';
</style>

<style scoped>
.img-details-blog {
  height: 50vh;
  object-fit: fill;
}
</style>
